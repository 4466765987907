import { mapState } from "vuex";
import {
  queryByProduction
} from "@/utils/api.js";
export default {
  components: {},
  data() {
    return {
      nolistImg: require("@/assets/order/noList.png"),
      showList: false,
      windowList: [],
      sortList: [
        {
          name: "发布时间",
          sortName:'salesPreferential.modifiedDate',
          sortStatus: 1 //0默认 1降 2升序
        },
        {
          name: "开始时间",
          sortName:'salesPreferential.startDate ',
          sortStatus: 0 //0默认 1降 2升序
        },
        {
          name: "结束时间",
          sortName:'salesPreferential.endDate',
          sortStatus: 0 //0默认 1降 2升序
        }
      ],
      dataList: [],
      pageLoadFlag: false,
      total: 0,
      totalPages: 0,
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      preperList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/preferential",
          name: "preferential",
          title: "特惠专区"
        }
      ],
      filterFrom: {
        reportCode: "",
        orgId: "",
        searchStr: "",
        status: "2",
        pageNo: 1,
        pageSize: 20,
        sortName:'salesPreferential.modifiedDate',
        sortType: "desc",
        matklId: ""
      },
      allcheckBox: false,
      isFixed: false,
      cartInfoTip:'',
      checkList:[], // 选中的商品
      isStock: true,// 仅看有货
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: state => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: state => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
    preperListShow: function() {
      if (this.allcheckBox) {
        // return this.preperList.filter(it => it.userBuy > 0);
        return this.checkList;
      } else {
        return this.preperList;
      }
    },
    chooseProductClass: function() {
      return this.preperList.filter(it => it.userBuy > 0).length;
    },
    allnum: function() {
      return this.checkList.reduce((tot, it) => {
        return tot + (parseInt(it.userBuy) || 0);
      }, 0);
    },
    allTotal: function() {
      let total = this.checkList.reduce((tot, it) => {
        return tot + (it.userBuy || 0) * (it.billPrice || 0);
      }, 0);
      return total.toFixed(2);
    },
    volumeTotal: function() {
      return this.checkList.reduce((tot, it) => {
        return tot + (it.userBuy || 0) * (it.loadVolume || 0);
      }, 0);
    }
  },
  created(){
    // 挂载后清空已选缓存
    sessionStorage.setItem('thCheckList','');
  },
  beforeDestroy(){
    // 销毁后清空已选缓存
    sessionStorage.setItem('thCheckList','');
  },
  methods: {
    isStockChange(e){
      this.isStock = e.target.checked
      this.$forceUpdate()
      this.getProductList()
    },
    // 获取免运费提示
    getMessage() {
      const msg =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14187495683'
      })
      this.cartInfoTip = msg.msgDesc
    },
    //获取商品列表
    async getProductList(type) {
      this.pageLoadFlag = true;
      
      if(type == "contract"){
        this.filterFrom.pageNo += 1;
      }
      this.filterFrom={
        ...this.filterFrom,
        isStock:this.isStock,
      }
      
      try {
        this.showList = false;
        const res = await queryByProduction(this.filterFrom);
        const { totalRows, preperList } = res.data;
        this.total = totalRows;
        this.totalPages = res.data.totalPages;
        this.pageLoadFlag = false;
        this.showList = true;
        this.$nextTick(() => {
          this.handleScroll();
        });
        if (type == "contract") {
          this.preperList = this.preperList.concat(preperList) || [];
        } else {
          this.preperList = preperList || [];
        }

        // 缓存中已选商品数据数量 覆盖新请求数据
        if(sessionStorage.getItem('thCheckList')){
          let preperList_ = this.preperList.slice();
          const checkList = JSON.parse(sessionStorage.getItem('thCheckList'));
          for (const item of preperList_) {
            for (const j of checkList) {
              if(item.id == j.id){
                item.userBuy = j.userBuy;
              }
            }
          }
          this.preperList = preperList_;
        }

      } catch (error) {
        this.pageLoadFlag = false;
        console.log(error);
      }
    },
    onFilterChange(checkedObj) {
      // // 更换删选条件 清空已选商品缓存
      // sessionStorage.setItem('thCheckList','');
      // 去除查看已选勾选状态
      this.allcheckBox = false;
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0], pageNo: 1 };
      //查询列表
      this.getProductList();
    },
    changeSearchVal(searchStr) {
      // 去除查看已选勾选状态
      this.filterFrom = { ...this.filterFrom, searchStr, pageNo: 1 };
    },
    onSearch(searchStr) {
      // 去除查看已选勾选状态
      this.allcheckBox = false;
      this.preperList = [];
      this.filterFrom = { ...this.filterFrom, searchStr, pageNo: 1 };
      this.getProductList();
    },
    prodValid(item){
      if(!item.standPrice){
       this.$nextTick(()=>{
         item.userBuy = 0
       })
        this.$message.warning('该商品没有提货价，无法下单！');
        return
      }

      let checkList = [];
      if(sessionStorage.getItem('thCheckList')){
        // 获取缓存已选数据 -> 里面有正在操作的同款商品 -> 数量>0替换, =0删除 
        checkList = JSON.parse(sessionStorage.getItem('thCheckList'));
        const hasItemIdx = checkList.findIndex(j=>j.id == item.id); // 缓存数据中已有的该商品的索引
        if(hasItemIdx > -1 && item.userBuy > 0){
          // 缓存中有该商品且当前数量>0 -> 替换数量
          checkList[hasItemIdx].userBuy = item.userBuy;
        }else if(hasItemIdx > -1 && item.userBuy  == 0){
          // =0删除
          checkList.splice(hasItemIdx,1);
        }else if(hasItemIdx == -1 && item.userBuy > 0){
          // 缓存中无该商品且当前数量>0 则添加
          checkList.push(item);
        }
        
      }else{
        if(item.userBuy > 0){
          // 无缓存且数量>0 缓存中添加商品
          checkList.push(item);
          // sessionStorage.setItem('thCheckList',JSON.stringify(checkList));
        }
      }
      sessionStorage.setItem('thCheckList',JSON.stringify(checkList));
      this.checkList = checkList;
    },
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.filterFrom = {
        ...this.filterFrom,
        sortType: sortStatus == 0 ? "" : sortStatus == 2 ? "asc" : "desc",
        sortName:data.sortName,
        pageNo: 1
      };
      this.getProductList();
    },

    // 商品数据重构
    getNewProductList(data) {
      let jmap = {};
      let newProduct = [];
      data.forEach(function(item) {
        var key = item.fwOrgName + "_" + item.matklName;
        if (typeof jmap[key] === "undefined") {
          jmap[key] = [];
        }
        item["userBuy"] = 0;
        jmap[key].push(item);
      });
      let keys = Object.keys(jmap);
      for (let i = 0; i < keys.length; i++) {
        let rs = keys[i].split("_");
        newProduct.push({
          productModel: rs[0],
          matklName: rs[1],
          chooseProductNum: 0,
          chooseProductPrice: 0,
          chooseProductClass: 0,
          chooseProductVolume: 0,
          checkChoose: false,
          checkAll: false,
          value: jmap[keys[i]]
        });
      }
      return newProduct;
    },

    handleSubmit() {
      if (this.allnum == 0) {
        // this.$message.warning("商品数量不可为0");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000056'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      let params = {
        flag: 1,
        mainId: ""
      };
      const ids = [];
      const counts = [];
      let fwOrgName;
      let matklId;
      let flag = false;
      const productModels = []
      let productModelFlag = false
      this.checkList.forEach(item => {
        if (item.userBuy && item.userBuy > 0) {
          if (fwOrgName && fwOrgName != item.fwOrgName) {
            flag = true;
          }
          if (matklId && matklId != item.matklId) {
            flag = true;
          }
          fwOrgName = item.fwOrgName;
          matklId = item.matklId;
          ids.push(item.id);
          counts.push(item.userBuy);
          if(productModels.includes(item.productModel+','+item.batch)){
            productModelFlag = true
          }
          productModels.push(item.productModel+','+item.batch)
        }
      });
      if(flag){
        // this.$message.warning("请选择同一供应商同一物料组商品！");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000057'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      if(productModelFlag){
        this.$message.warning("同型号同批次商品请分别下单！");
        return false;
      }
      params.ids = ids.join(",");
      params.counts = counts.join(",");
      this.$router.push({
        path: "/confirm/special",
        query: params
      });
    },

    handlePageChange() {
      this.getNewProductList();
    },

    //查看已选
    checkHasChoose(index) {
      this.preperList[index] = {
        ...this.preperList[index],
        checkChoose: this.preperList[index].checkChoose
      };
      this.$forceUpdate();
    },

    //全选
    CheckAllEdit(index) {
      if (this.preperList[index].checkAll) {
        this.preperList[index].value.forEach(item => {
          item.userBuy == 0 ? (item.userBuy += 1) : item.userBuy;
        });
      } else {
        this.preperList[index].value.forEach(item => {
          item.userBuy = 0;
        });
      }
      this.dataChange(index);
      this.$forceUpdate();
    },

    //获取物流提示
    getInfo() {
      this.$info({
        title: "体积提示",
        content: this.$store.state.user.getVolumnText
      });
    },

    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    }
  },
  mounted() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode:this.filterConfigStr ? this.filterConfigStr: '' };
    //默认查询入参
    if (this.$route.query.condition) {
      this.filterFrom.condition = this.$route.query.condition;
    }
    //最后一项面包屑显示
    let breadcrumbLast = {
      title: this.$route.query.customName
        ?  this.$route.query.customName
        : "",
    };
    //最后一项面包屑
    if (breadcrumbLast.title) {
      this.$set(this.breadcrumbData, this.breadcrumbData.length, breadcrumbLast);
    }
    window.addEventListener(
      "scroll",
      this.throttle(this.handleScroll, 10),
      true
    );
    this.getMessage()
  }
};
